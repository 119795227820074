@import url('https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    @apply text-[16px] xs:text-[17px];
}

body {
    @apply
    bg-black
    text-white
    overflow-x-hidden;
}

#background {
    @apply
    absolute inset-0
    bg-slate-900
    -z-50;
    mask: url("patterns/hexagons.svg") 0 0 / 20px;
}

h1 {
    @apply mx-4 xs:mx-auto max-w-lg text-xl font-bold uppercase border-b border-cyan-700;
}

h2 {
    @apply mx-4 xs:mx-auto max-w-lg font-bold uppercase border-b border-cyan-700;
}

.corners {
    @apply
    relative
    ;

    &:after {
        content: "";
        @apply
        absolute -inset-px
        ring-cyan-300
        ;
        --corner-size: 6px;
        --corner-color: var(--tw-ring-color);
        --corner-border: 1px;
        background: linear-gradient(to right, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 0,
        linear-gradient(to right, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 100%,
        linear-gradient(to left, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 0,
        linear-gradient(to left, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 100%,
        linear-gradient(to bottom, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 0,
        linear-gradient(to bottom, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 0,
        linear-gradient(to top, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 100%,
        linear-gradient(to top, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 100%;
        background-repeat: no-repeat;
        background-size: var(--corner-size) var(--corner-size);
    }
}

.circles {
    @apply absolute -z-40;

    .circle {
        @apply
        absolute
        rounded-full
        border border-cyan-700;
    }
}

.squares {
    @apply relative;

    .square {
        @apply
        absolute
        border border-blue-700;
    }
}

.diamonds {
    @apply
    relative
    rotate-45;

    .diamond {
        @apply
        absolute
        border border-blue-700;
    }
}

.tag {
    @apply
    px-2
    h-4
    flex justify-center items-center
    text-sm font-bold uppercase tracking-wide leading-none;
}

.blue-tag {
    @apply
    text-sky-900 bg-sky-200;
}

.purple-tag {
    @apply
    text-fuchsia-900 bg-fuchsia-200;
}

.green-tag {
    @apply
    text-green-900 bg-green-200;
}

#intro {
    @apply relative;

    #hero {
        @apply
        pt-16 xs:pt-32
        xs:grid xs:grid-cols-[1fr_10rem_22rem_1fr]
        ;

        .hero-space, .hero-content {
            @apply
            border-t border-cyan-700;
        }

        .hero-content {
            @apply
            xs:border-l;

            &:nth-child(odd) {
                @apply
                xs:border-r;
            }
        }

        .text {
            @apply
            px-4 py-2
            xs:font-thin text-3xl xs:text-4xl;
        }

        .text-gradient {
            @apply text-transparent bg-clip-text bg-gradient-to-r;
        }

        .text-glow {
            @apply
            absolute inset-0
            -translate-x-px
            font-medium
            blur-sm;
        }

        /*
        .borders {
            @apply xs:border-x border-cyan-700;
        }

        .borders-alt {
            @apply xs:border-l border-cyan-700;
        }
        */

        .squares-container {
            @apply
            absolute inset-y-0 left-4
            flex items-center
            space-x-4;
        }

        .diamonds-container {
            @apply
            absolute inset-y-0 right-4
            flex items-center
            space-x-4;
        }
    }

    #pitch {
        @apply
        mx-auto mt-16
        max-w-lg
        relative;

        .text {
            @apply
            px-4 xs:px-6 py-4
            bg-blue-900/50;
        }
    }

    #skills {
        @apply mt-16;

        .content {
            @apply
            mx-4 xs:mx-auto mt-8
            max-w-lg
            flex flex-col items-start 2xs:flex-row 2xs:flex-wrap gap-6;

            .skill {
                @apply
                ml-[10px]
                border-b border-blue-500
                flex items-center
                leading-none;

                &:before {
                    content: "";
                    @apply
                    block
                    w-[21px] h-[21px]
                    bg-blue-500
                    -translate-x-[10px] translate-y-px
                    transition;
                    mask: url("patterns/diamond.svg") 0 0 / 21px;
                }

                &:hover {
                    &:before {
                        @apply rotate-45 scale-50 -translate-x-[7px];
                    }
                }
            }
        }
    }

    #links {
        @apply mt-16;

        .content {
            @apply
            mx-4 xs:mx-auto mt-8
            max-w-lg
            grid grid-cols-1 2xs:grid-cols-2
            /*border-t border-x border-cyan-700*/
            ;

            .link {
                @apply
                p-4
                /*border-b 2xs:even:border-l border-cyan-700*/
                ;

                &:hover {
                    .link-content {
                        transform: rotate(0) scale(1) translate(0);

                        i {
                            box-shadow: 0 0 12px 0 var(--tw-shadow-color);
                        }
                    }
                }

                &:active {
                    .link-content {
                        transform: scale(.97);
                    }
                }

                .link-content {
                    @apply
                    flex items-stretch
                    transition;
                    transform: perspective(900px) rotateX(15deg) scale(0.95);
                }

                i {
                    @apply
                    w-8 h-8
                    text-xl text-black
                    bg-cyan-300
                    flex justify-center items-center
                    shadow-cyan-300
                    transition;
                }


                span {
                    @apply
                    flex-1
                    px-2
                    flex items-center
                    border border-cyan-300
                    font-semibold
                    relative;

                    &:after {
                        content: "";
                        @apply
                        block
                        absolute inset-0
                        bg-cyan-700
                        -z-10;
                        mask: url("patterns/diagonal-lines.svg");
                    }
                }
            }
        }
    }
}

#work-experience {
    @apply
    pt-16 xs:pt-32;

    .scroll-content {
        @apply
        pt-8
        flex
        overflow-x-scroll snap-x scroll-pl-16;

        /* Hide scrollbar for IE, Edge and Firefox */
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        &::-webkit-scrollbar {
            /* Hide scrollbar for Chrome, Safari and Opera */
            display: none;
        }

        .job {
            @apply
            flex-none snap-start
            max-w-sm
            ml-24 last:mr-16
            space-y-4
            transition ;
            transform: perspective(900px) rotateY(9deg) scale(0.95);
            transform-origin: left;

            &:hover {
                transform: rotate(0) scale(1) translate(0);

                .job-hanger {
                    @apply
                    border-l-blue-700/100;

                    .job-hanger-dot {
                        @apply opacity-100;
                    }
                }

                .job-logo {
                    box-shadow: 0 0 24px -3px var(--tw-shadow-color);
                }
            }

            &:active {
                .job-info {
                    transform: scale(.97);
                }
            }

            .job-hanger {
                @apply
                h-16
                border-l border-l-blue-700/0 border-b border-blue-700
                transition
                relative;

                .job-hanger-dot {
                    @apply
                    absolute -top-1 -left-1
                    w-2 h-2
                    bg-blue-300
                    transition
                    opacity-0;
                }
            }

            .job-info {
                @apply
                flex items-center gap-x-4
                transition
                relative;
            }

            .job-number {
                @apply
                absolute -left-4 bottom-0
                w-16
                px-0.5
                text-black bg-white leading-none
                -rotate-90 origin-bottom-left;

                span {
                    @apply font-bold;
                }
            }

            .job-logo {
                @apply
                row-span-2
                w-16
                shadow-cyan-300
                transition duration-300;
            }

            .job-date {
                @apply
                self-end
                font-semibold leading-none;

                span {
                    @apply font-normal;
                }
            }

            .job-company {
                @apply text-3xl font-semibold leading-none;
            }

            .job-description {
                @apply
                px-3 py-2
                bg-blue-900/50;
            }

            .job-tags {
                @apply flex gap-2;
            }
        }
    }
}

.projects {
    @apply
    pt-16 xs:pt-32
    relative;

    .content {
        @apply
        mt-16
        px-4 md:px-16
        grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-16 md:gap-20 justify-items-center;

        .project {
            @apply
            w-full
            max-w-md
            pl-4
            space-y-4
            transition;
            transform: perspective(900px) rotateX(9deg) scale(0.95);

            &:hover {
                transform: rotate(0) scale(1) translate(0);

                .project-logo {
                    box-shadow: 0 0 24px -3px var(--tw-shadow-color);
                }
            }

            &:active {
                .project-info, .project-info-alt {
                    transform: scale(.97);
                }
            }

            .project-info {
                @apply
                flex items-center gap-x-4
                border
                transition
                relative;

                &:after {
                    content: "";
                    @apply
                    block
                    absolute inset-0
                    -z-10;
                    mask: url("patterns/diagonal-lines.svg");
                }
            }

            .project-info-alt {
                @apply
                h-16
                flex flex-col justify-center
                pl-3
                border
                transition
                relative;

                &:after {
                    content: "";
                    @apply
                    block
                    absolute inset-0
                    -z-10;
                    mask: url("patterns/diagonal-lines.svg");
                }
            }

            .project-number {
                @apply
                absolute -left-4 bottom-0
                w-16
                px-0.5
                text-black bg-white leading-none
                -rotate-90 origin-bottom-left;

                span {
                    @apply font-bold;
                }
            }

            .project-logo {
                @apply
                row-span-2
                w-16
                p-2
                shadow-cyan-300
                transition duration-300
            }

            .project-date {
                @apply
                pt-1
                font-semibold leading-none;

                span {
                    @apply font-normal;
                }
            }

            .project-name {
                @apply text-3xl font-semibold leading-none;
            }

            .project-description {
                @apply
                px-3 py-2;
            }

            .project-tags {
                @apply flex gap-2;
            }
        }
    }
}

#apps {
    .content {
        .project {
            .project-info {
                @apply border-indigo-900;

                &:after {
                    @apply bg-indigo-700;
                }

                .project-logo {
                    @apply bg-indigo-900;
                }
            }

            .project-description {
                @apply bg-indigo-900/50;
            }
        }
    }
}

#games {
    .content {
        .project {
            .project-info {
                @apply border-violet-900;

                &:after {
                    @apply bg-violet-700;
                }

                .project-logo {
                    @apply bg-violet-900;
                }
            }

            .project-description {
                @apply bg-violet-900/50;
            }
        }
    }
}

#flutter-packages {
    .content {
        .project {
            .project-info-alt {
                @apply border-purple-900;

                &:after {
                    @apply bg-purple-700;
                }
            }

            .project-description {
                @apply bg-purple-900/50;
            }
        }
    }
}

#websites {
    .content {
        .project {
            .project-info-alt {
                @apply border-fuchsia-900;

                &:after {
                    @apply bg-fuchsia-700;
                }
            }

            .project-description {
                @apply bg-fuchsia-900/50;
            }
        }
    }
}

footer {
    @apply
    mt-32 pb-8
    flex justify-center
    relative;
}
