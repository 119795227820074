@import "https://fonts.googleapis.com/css2?family=Rajdhani:wght@300;400;500;600;700&display=swap";
*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: Rajdhani, sans-serif;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden]:where(:not([hidden="until-found"])) {
  display: none;
}

.static {
  position: static;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.-inset-px {
  inset: -1px;
}

.inset-0 {
  inset: 0;
}

.inset-1 {
  inset: .25rem;
}

.inset-1\/4 {
  inset: 25%;
}

.inset-2 {
  inset: .5rem;
}

.inset-x-0 {
  left: 0;
  right: 0;
}

.inset-y-0 {
  top: 0;
  bottom: 0;
}

.-bottom-6 {
  bottom: -1.5rem;
}

.-left-1 {
  left: -.25rem;
}

.-left-4 {
  left: -1rem;
}

.-left-72 {
  left: -18rem;
}

.-right-32 {
  right: -8rem;
}

.-right-48 {
  right: -12rem;
}

.-top-1 {
  top: -.25rem;
}

.-top-20 {
  top: -5rem;
}

.-top-6 {
  top: -1.5rem;
}

.bottom-0 {
  bottom: 0;
}

.bottom-32 {
  bottom: 8rem;
}

.bottom-8 {
  bottom: 2rem;
}

.left-16 {
  left: 4rem;
}

.left-4 {
  left: 1rem;
}

.right-20 {
  right: 5rem;
}

.right-4 {
  right: 1rem;
}

.right-72 {
  right: 18rem;
}

.top-16 {
  top: 4rem;
}

.top-20 {
  top: 5rem;
}

.top-72 {
  top: 18rem;
}

.top-\[32rem\] {
  top: 32rem;
}

.row-span-2 {
  grid-row: span 2 / span 2;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.ml-24 {
  margin-left: 6rem;
}

.ml-\[10px\] {
  margin-left: 10px;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-8 {
  margin-top: 2rem;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-16 {
  height: 4rem;
}

.h-2 {
  height: .5rem;
}

.h-3 {
  height: .75rem;
}

.h-4 {
  height: 1rem;
}

.h-48 {
  height: 12rem;
}

.h-6 {
  height: 1.5rem;
}

.h-64 {
  height: 16rem;
}

.h-8 {
  height: 2rem;
}

.h-96 {
  height: 24rem;
}

.h-\[21px\] {
  height: 21px;
}

.h-\[56rem\] {
  height: 56rem;
}

.h-px {
  height: 1px;
}

.w-16 {
  width: 4rem;
}

.w-2 {
  width: .5rem;
}

.w-48 {
  width: 12rem;
}

.w-6 {
  width: 1.5rem;
}

.w-64 {
  width: 16rem;
}

.w-8 {
  width: 2rem;
}

.w-96 {
  width: 24rem;
}

.w-\[21px\] {
  width: 21px;
}

.w-\[56rem\] {
  width: 56rem;
}

.w-full {
  width: 100%;
}

.max-w-lg {
  max-width: 32rem;
}

.max-w-md {
  max-width: 28rem;
}

.max-w-sm {
  max-width: 24rem;
}

.flex-1 {
  flex: 1;
}

.flex-none {
  flex: none;
}

.-translate-x-\[10px\] {
  --tw-translate-x: -10px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-\[7px\] {
  --tw-translate-x: -7px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-x-px {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-translate-y-px {
  --tw-translate-y: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-8 {
  --tw-translate-y: 2rem;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.translate-y-px {
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-rotate-90 {
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.rotate-45 {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.scale-50 {
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transform {
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@keyframes ping {
  75%, 100% {
    opacity: 0;
    transform: scale(2);
  }
}

.animate-\[ping_3s_linear_infinite\] {
  animation: 3s linear infinite ping;
}

.snap-x {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
}

.snap-start {
  scroll-snap-align: start;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.items-start {
  align-items: flex-start;
}

.items-center {
  align-items: center;
}

.items-stretch {
  align-items: stretch;
}

.justify-center {
  justify-content: center;
}

.gap-16 {
  gap: 4rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.space-y-4 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.self-end {
  align-self: flex-end;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.rounded-full {
  border-radius: 9999px;
}

.border {
  border-width: 1px;
}

.border-x {
  border-left-width: 1px;
  border-right-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-l {
  border-left-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-blue-500 {
  --tw-border-opacity: 1;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
}

.border-blue-700 {
  --tw-border-opacity: 1;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
}

.border-cyan-300 {
  --tw-border-opacity: 1;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
}

.border-cyan-700 {
  --tw-border-opacity: 1;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

.border-l-blue-700\/0 {
  border-left-color: #1d4ed800;
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
}

.bg-blue-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
}

.bg-blue-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
}

.bg-blue-900\/50 {
  background-color: #1e3a8a80;
}

.bg-cyan-300 {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
}

.bg-cyan-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
}

.bg-slate-900 {
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.from-cyan-300 {
  --tw-gradient-from: #67e8f9 var(--tw-gradient-from-position);
  --tw-gradient-to: #67e8f900 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-purple-500 {
  --tw-gradient-from: #a855f7 var(--tw-gradient-from-position);
  --tw-gradient-to: #a855f700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-yellow-300 {
  --tw-gradient-from: #fde047 var(--tw-gradient-from-position);
  --tw-gradient-to: #fde04700 var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.to-blue-500 {
  --tw-gradient-to: #3b82f6 var(--tw-gradient-to-position);
}

.to-orange-600 {
  --tw-gradient-to: #ea580c var(--tw-gradient-to-position);
}

.to-pink-400 {
  --tw-gradient-to: #f472b6 var(--tw-gradient-to-position);
}

.bg-clip-text {
  background-clip: text;
}

.p-2 {
  padding: .5rem;
}

.p-4 {
  padding: 1rem;
}

.px-0\.5 {
  padding-left: .125rem;
  padding-right: .125rem;
}

.px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-3 {
  padding-left: .75rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-1 {
  padding-top: .25rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pt-8 {
  padding-top: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-\[16px\] {
  font-size: 16px;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.font-bold {
  font-weight: 700;
}

.font-medium {
  font-weight: 500;
}

.font-semibold {
  font-weight: 600;
}

.uppercase {
  text-transform: uppercase;
}

.leading-none {
  line-height: 1;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
}

.text-fuchsia-900 {
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.text-green-900 {
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

.text-sky-900 {
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.text-transparent {
  color: #0000;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.opacity-75 {
  opacity: .75;
}

.shadow-cyan-300 {
  --tw-shadow-color: #67e8f9;
  --tw-shadow: var(--tw-shadow-colored);
}

.ring-cyan-300 {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity));
}

.transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.duration-300 {
  transition-duration: .3s;
}

html {
  font-size: 16px;
}

@media (width >= 560px) {
  html {
    font-size: 17px;
  }
}

body {
  --tw-bg-opacity: 1;
  background-color: rgb(0 0 0 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  overflow-x: hidden;
}

#background {
  z-index: -50;
  --tw-bg-opacity: 1;
  background-color: rgb(15 23 42 / var(--tw-bg-opacity));
  position: absolute;
  inset: 0;
  mask: url("hexagons.d8660a11.svg") 0 0 / 20px;
}

h1 {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
  text-transform: uppercase;
  max-width: 32rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1.75rem;
}

@media (width >= 560px) {
  h1 {
    margin-left: auto;
    margin-right: auto;
  }
}

h2 {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
  text-transform: uppercase;
  max-width: 32rem;
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: 700;
}

@media (width >= 560px) {
  h2 {
    margin-left: auto;
    margin-right: auto;
  }
}

.corners {
  position: relative;
}

.corners:after {
  content: "";
  --tw-ring-opacity: 1;
  --tw-ring-color: rgb(103 232 249 / var(--tw-ring-opacity));
  --corner-size: 6px;
  --corner-color: var(--tw-ring-color);
  --corner-border: 1px;
  background: linear-gradient(to right, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 0, linear-gradient(to right, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 100%, linear-gradient(to left, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 0, linear-gradient(to left, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 100%, linear-gradient(to bottom, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 0, linear-gradient(to bottom, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 0, linear-gradient(to top, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 0 100%, linear-gradient(to top, var(--corner-color) var(--corner-border), transparent var(--corner-border)) 100% 100%;
  background-repeat: no-repeat;
  background-size: var(--corner-size) var(--corner-size);
  position: absolute;
  inset: -1px;
}

.circles {
  z-index: -40;
  position: absolute;
}

.circles .circle {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
  border-radius: 9999px;
  position: absolute;
}

.squares {
  position: relative;
}

.squares .square {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
  position: absolute;
}

.diamonds {
  --tw-rotate: 45deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: relative;
}

.diamonds .diamond {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
  position: absolute;
}

.tag {
  text-transform: uppercase;
  letter-spacing: .025em;
  justify-content: center;
  align-items: center;
  height: 1rem;
  padding-left: .5rem;
  padding-right: .5rem;
  font-size: .875rem;
  font-weight: 700;
  line-height: 1;
  display: flex;
}

.blue-tag {
  --tw-bg-opacity: 1;
  background-color: rgb(186 230 253 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(12 74 110 / var(--tw-text-opacity));
}

.purple-tag {
  --tw-bg-opacity: 1;
  background-color: rgb(245 208 254 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(112 26 117 / var(--tw-text-opacity));
}

.green-tag {
  --tw-bg-opacity: 1;
  background-color: rgb(187 247 208 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(20 83 45 / var(--tw-text-opacity));
}

#intro {
  position: relative;
}

#intro #hero {
  padding-top: 4rem;
}

@media (width >= 560px) {
  #intro #hero {
    grid-template-columns: 1fr 10rem 22rem 1fr;
    padding-top: 8rem;
    display: grid;
  }
}

#intro #hero .hero-space, #intro #hero .hero-content {
  --tw-border-opacity: 1;
  border-top-width: 1px;
  border-color: rgb(14 116 144 / var(--tw-border-opacity));
}

@media (width >= 560px) {
  #intro #hero .hero-content {
    border-left-width: 1px;
  }

  #intro #hero .hero-content:nth-child(odd) {
    border-right-width: 1px;
  }
}

#intro #hero .text {
  padding: .5rem 1rem;
  font-size: 1.875rem;
  line-height: 2.25rem;
}

@media (width >= 560px) {
  #intro #hero .text {
    font-size: 2.25rem;
    font-weight: 100;
    line-height: 2.5rem;
  }
}

#intro #hero .text-gradient {
  background-image: linear-gradient(to right, var(--tw-gradient-stops));
  color: #0000;
  background-clip: text;
}

#intro #hero .text-glow {
  --tw-translate-x: -1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-blur: blur(4px);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  font-weight: 500;
  position: absolute;
  inset: 0;
}

#intro #hero .squares-container {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 1rem;
}

#intro #hero .squares-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

#intro #hero .diamonds-container {
  align-items: center;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 1rem;
}

#intro #hero .diamonds-container > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(1rem * var(--tw-space-x-reverse));
  margin-left: calc(1rem * calc(1 - var(--tw-space-x-reverse)));
}

#intro #pitch {
  max-width: 32rem;
  margin-top: 4rem;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

#intro #pitch .text {
  background-color: #1e3a8a80;
  padding: 1rem;
}

@media (width >= 560px) {
  #intro #pitch .text {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

#intro #skills {
  margin-top: 4rem;
}

#intro #skills .content {
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  max-width: 32rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: flex;
}

@media (width >= 480px) {
  #intro #skills .content {
    flex-flow: wrap;
  }
}

@media (width >= 560px) {
  #intro #skills .content {
    margin-left: auto;
    margin-right: auto;
  }
}

#intro #skills .content .skill {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-color: rgb(59 130 246 / var(--tw-border-opacity));
  align-items: center;
  margin-left: 10px;
  line-height: 1;
  display: flex;
}

#intro #skills .content .skill:before {
  content: "";
  --tw-translate-x: -10px;
  --tw-translate-y: 1px;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(59 130 246 / var(--tw-bg-opacity));
  width: 21px;
  height: 21px;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: block;
  mask: url("diamond.db57422e.svg") 0 0 / 21px;
}

#intro #skills .content .skill:hover:before {
  --tw-translate-x: -7px;
  --tw-rotate: 45deg;
  --tw-scale-x: .5;
  --tw-scale-y: .5;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

#intro #links {
  margin-top: 4rem;
}

#intro #links .content {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  max-width: 32rem;
  margin-top: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
  display: grid;
}

@media (width >= 480px) {
  #intro #links .content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
}

@media (width >= 560px) {
  #intro #links .content {
    margin-left: auto;
    margin-right: auto;
  }
}

#intro #links .content .link {
  padding: 1rem;
}

#intro #links .content .link:hover .link-content {
  transform: rotate(0)scale(1)translate(0);
}

#intro #links .content .link:hover .link-content i {
  box-shadow: 0 0 12px 0 var(--tw-shadow-color);
}

#intro #links .content .link:active .link-content {
  transform: scale(.97);
}

#intro #links .content .link .link-content {
  align-items: stretch;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  transform: perspective(900px)rotateX(15deg)scale(.95);
}

#intro #links .content .link i {
  --tw-bg-opacity: 1;
  background-color: rgb(103 232 249 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  --tw-shadow-color: #67e8f9;
  --tw-shadow: var(--tw-shadow-colored);
  justify-content: center;
  align-items: center;
  width: 2rem;
  height: 2rem;
  font-size: 1.25rem;
  line-height: 1.75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
}

#intro #links .content .link span {
  --tw-border-opacity: 1;
  border-width: 1px;
  border-color: rgb(103 232 249 / var(--tw-border-opacity));
  flex: 1;
  align-items: center;
  padding-left: .5rem;
  padding-right: .5rem;
  font-weight: 600;
  display: flex;
  position: relative;
}

#intro #links .content .link span:after {
  content: "";
  z-index: -10;
  --tw-bg-opacity: 1;
  background-color: rgb(14 116 144 / var(--tw-bg-opacity));
  display: block;
  position: absolute;
  inset: 0;
  mask: url("diagonal-lines.7084c657.svg");
}

#work-experience {
  padding-top: 4rem;
}

@media (width >= 560px) {
  #work-experience {
    padding-top: 8rem;
  }
}

#work-experience .scroll-content {
  scroll-snap-type: x var(--tw-scroll-snap-strictness);
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-top: 2rem;
  scroll-padding-left: 4rem;
  display: flex;
  overflow-x: scroll;
}

#work-experience .scroll-content::-webkit-scrollbar {
  display: none;
}

#work-experience .scroll-content .job {
  scroll-snap-align: start;
  flex: none;
  max-width: 24rem;
  margin-left: 6rem;
}

#work-experience .scroll-content .job > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

#work-experience .scroll-content .job {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#work-experience .scroll-content .job:last-child {
  margin-right: 4rem;
}

#work-experience .scroll-content .job {
  transform-origin: 0;
  transform: perspective(900px)rotateY(9deg)scale(.95);
}

#work-experience .scroll-content .job:hover {
  transform: rotate(0)scale(1)translate(0);
}

#work-experience .scroll-content .job:hover .job-hanger {
  border-left-color: #1d4ed8;
}

#work-experience .scroll-content .job:hover .job-hanger .job-hanger-dot {
  opacity: 1;
}

#work-experience .scroll-content .job:hover .job-logo {
  box-shadow: 0 0 24px -3px var(--tw-shadow-color);
}

#work-experience .scroll-content .job:active .job-info {
  transform: scale(.97);
}

#work-experience .scroll-content .job .job-hanger {
  --tw-border-opacity: 1;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-color: rgb(29 78 216 / var(--tw-border-opacity));
  border-left-color: #1d4ed800;
  height: 4rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: relative;
}

#work-experience .scroll-content .job .job-hanger .job-hanger-dot {
  --tw-bg-opacity: 1;
  background-color: rgb(147 197 253 / var(--tw-bg-opacity));
  opacity: 0;
  width: .5rem;
  height: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: absolute;
  top: -.25rem;
  left: -.25rem;
}

#work-experience .scroll-content .job .job-info {
  align-items: center;
  column-gap: 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

#work-experience .scroll-content .job .job-number {
  transform-origin: 0 100%;
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  width: 4rem;
  padding-left: .125rem;
  padding-right: .125rem;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: -1rem;
}

#work-experience .scroll-content .job .job-number span {
  font-weight: 700;
}

#work-experience .scroll-content .job .job-logo {
  --tw-shadow-color: #67e8f9;
  --tw-shadow: var(--tw-shadow-colored);
  grid-row: span 2 / span 2;
  width: 4rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

#work-experience .scroll-content .job .job-date {
  align-self: flex-end;
  font-weight: 600;
  line-height: 1;
}

#work-experience .scroll-content .job .job-date span {
  font-weight: 400;
}

#work-experience .scroll-content .job .job-company {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1;
}

#work-experience .scroll-content .job .job-description {
  background-color: #1e3a8a80;
  padding: .5rem .75rem;
}

#work-experience .scroll-content .job .job-tags {
  gap: .5rem;
  display: flex;
}

.projects {
  padding-top: 4rem;
  position: relative;
}

@media (width >= 560px) {
  .projects {
    padding-top: 8rem;
  }
}

.projects .content {
  grid-template-columns: repeat(1, minmax(0, 1fr));
  justify-items: center;
  gap: 4rem;
  margin-top: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  display: grid;
}

@media (width >= 768px) {
  .projects .content {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 5rem;
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (width >= 1280px) {
  .projects .content {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}

.projects .content .project {
  width: 100%;
  max-width: 28rem;
}

.projects .content .project > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(1rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(1rem * var(--tw-space-y-reverse));
}

.projects .content .project {
  padding-left: 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  transform: perspective(900px)rotateX(9deg)scale(.95);
}

.projects .content .project:hover {
  transform: rotate(0)scale(1)translate(0);
}

.projects .content .project:hover .project-logo {
  box-shadow: 0 0 24px -3px var(--tw-shadow-color);
}

.projects .content .project:active .project-info, .projects .content .project:active .project-info-alt {
  transform: scale(.97);
}

.projects .content .project .project-info {
  border-width: 1px;
  align-items: center;
  column-gap: 1rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

.projects .content .project .project-info:after {
  content: "";
  z-index: -10;
  display: block;
  position: absolute;
  inset: 0;
  mask: url("diagonal-lines.7084c657.svg");
}

.projects .content .project .project-info-alt {
  border-width: 1px;
  flex-direction: column;
  justify-content: center;
  height: 4rem;
  padding-left: .75rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  display: flex;
  position: relative;
}

.projects .content .project .project-info-alt:after {
  content: "";
  z-index: -10;
  display: block;
  position: absolute;
  inset: 0;
  mask: url("diagonal-lines.7084c657.svg");
}

.projects .content .project .project-number {
  transform-origin: 0 100%;
  --tw-rotate: -90deg;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(0 0 0 / var(--tw-text-opacity));
  width: 4rem;
  padding-left: .125rem;
  padding-right: .125rem;
  line-height: 1;
  position: absolute;
  bottom: 0;
  left: -1rem;
}

.projects .content .project .project-number span {
  font-weight: 700;
}

.projects .content .project .project-logo {
  --tw-shadow-color: #67e8f9;
  --tw-shadow: var(--tw-shadow-colored);
  grid-row: span 2 / span 2;
  width: 4rem;
  padding: .5rem;
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.projects .content .project .project-date {
  padding-top: .25rem;
  font-weight: 600;
  line-height: 1;
}

.projects .content .project .project-date span {
  font-weight: 400;
}

.projects .content .project .project-name {
  font-size: 1.875rem;
  font-weight: 600;
  line-height: 1;
}

.projects .content .project .project-description {
  padding: .5rem .75rem;
}

.projects .content .project .project-tags {
  gap: .5rem;
  display: flex;
}

#apps .content .project .project-info {
  --tw-border-opacity: 1;
  border-color: rgb(49 46 129 / var(--tw-border-opacity));
}

#apps .content .project .project-info:after {
  --tw-bg-opacity: 1;
  background-color: rgb(67 56 202 / var(--tw-bg-opacity));
}

#apps .content .project .project-info .project-logo {
  --tw-bg-opacity: 1;
  background-color: rgb(49 46 129 / var(--tw-bg-opacity));
}

#apps .content .project .project-description {
  background-color: #312e8180;
}

#games .content .project .project-info {
  --tw-border-opacity: 1;
  border-color: rgb(76 29 149 / var(--tw-border-opacity));
}

#games .content .project .project-info:after {
  --tw-bg-opacity: 1;
  background-color: rgb(109 40 217 / var(--tw-bg-opacity));
}

#games .content .project .project-info .project-logo {
  --tw-bg-opacity: 1;
  background-color: rgb(76 29 149 / var(--tw-bg-opacity));
}

#games .content .project .project-description {
  background-color: #4c1d9580;
}

#flutter-packages .content .project .project-info-alt {
  --tw-border-opacity: 1;
  border-color: rgb(88 28 135 / var(--tw-border-opacity));
}

#flutter-packages .content .project .project-info-alt:after {
  --tw-bg-opacity: 1;
  background-color: rgb(126 34 206 / var(--tw-bg-opacity));
}

#flutter-packages .content .project .project-description {
  background-color: #581c8780;
}

#websites .content .project .project-info-alt {
  --tw-border-opacity: 1;
  border-color: rgb(112 26 117 / var(--tw-border-opacity));
}

#websites .content .project .project-info-alt:after {
  --tw-bg-opacity: 1;
  background-color: rgb(162 28 175 / var(--tw-bg-opacity));
}

#websites .content .project .project-description {
  background-color: #701a7580;
}

footer {
  justify-content: center;
  margin-top: 8rem;
  padding-bottom: 2rem;
  display: flex;
  position: relative;
}

.last\:mr-16:last-child {
  margin-right: 4rem;
}

@media (width >= 480px) {
  .\32 xs\:col-span-2 {
    grid-column: span 2 / span 2;
  }

  .\32 xs\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .\32 xs\:flex-row {
    flex-direction: row;
  }

  .\32 xs\:flex-wrap {
    flex-wrap: wrap;
  }

  .\32 xs\:even\:border-l:nth-child(2n) {
    border-left-width: 1px;
  }
}

@media (width >= 560px) {
  .xs\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }

  .xs\:block {
    display: block;
  }

  .xs\:grid {
    display: grid;
  }

  .xs\:grid-cols-\[1fr_10rem_22rem_1fr\] {
    grid-template-columns: 1fr 10rem 22rem 1fr;
  }

  .xs\:border-x {
    border-left-width: 1px;
    border-right-width: 1px;
  }

  .xs\:border-l {
    border-left-width: 1px;
  }

  .xs\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xs\:pt-32 {
    padding-top: 8rem;
  }

  .xs\:text-right {
    text-align: right;
  }

  .xs\:text-\[17px\] {
    font-size: 17px;
  }

  .xs\:font-thin {
    font-weight: 100;
  }
}

@media (width >= 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:gap-20 {
    gap: 5rem;
  }

  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
}

@media (width >= 1280px) {
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
}
/*# sourceMappingURL=index.084f36f8.css.map */
